import { reactive } from 'vue'
import type { FormObject } from '../form/form.d'

export default class FormBase implements FormObject {
  public form: any
  public errors = reactive({})

  fromSchema (
    schema = {}): any {
    return {}
  }

  toJSON (): any {
    return ''
  }

  formItems (step: string): any {
    return {}
  }

  find (key: string): any {
    return {}
  }

  findByGroup (key: string): any {
    return {}
  }

  hasErrorByStep (step: string): boolean {
    return false
  }
}
