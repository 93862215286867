/**
 * google tag manager の gtag.js を読み込む script オブジェクトを生成する
 *
 * document オブジェクトと window オブジェクトを引数に与えて実行する
 *
 * @param {object} args
 * @param {Document} args.document
 * @param {Window} args.window
 * @return {Function}
 */
export default function loadGtag ({ document, window }) {
  if (document) {
    const script = document.createElement('script')

    script.src = 'https://www.googletagmanager.com/gtag/js'
    script.async = true

    const entry = document.getElementsByTagName('script')[0]
    entry.parentNode.insertBefore(script, entry)
  }

  window.dataLayer = window.dataLayer || []

  return function gtag () {
    window.dataLayer.push(arguments) //eslint-disable-line
  }
}
