import { FormObject } from '../form/form.d'

export default class SetDefaultPrefectureUsecase {
  public readonly service: string
  private readonly form: FormObject
  private readonly prefecture: any

  /**
   * @param {object} params
   * @param {string} params.service
   * @param {FormObject} params.form
   * @param {object} prefecture
   * @param params.prefecture
   */
  constructor ({
    service,
    form,
    prefecture
  }) {
    this.service = service
    this.form = form
    this.prefecture = prefecture
  }

  execute (): void {
    const propertyPrefecture = this.form.find('property_prefecture')
    propertyPrefecture.value = this.prefecture.name
    if (typeof this.prefecture.name === 'undefined') {
      propertyPrefecture.readonly = false
      throw Error('data-property-prefecture is undefined')
    }
  }
}
