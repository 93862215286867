<template>
  <div
    class="form__step-item"
    :class="{ 'form__step--active': $route.name === active }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: ['active']
}
</script>
