import { reactive } from 'vue'
import FormBase from './form_base'
import { FormGroupItem } from './form_group_item'
import { BuiltYearOptionsGenerator } from './built-year-options-generator'

/**
 * フォームのスキーマが存在しない場合のエラー
 */
class FromSchemaNotDefined extends Error {
  /**
   * @return {string}
   */
  get name (): string { return 'FromSchemaNotDefined' }
}

/**
 * 見積り依頼のフォームのスキーマ
 */
export default class QuotationOrderForm extends FormBase {
  private readonly buildYear: Array<{name: string}>

  /**
   * @return {void}
   */
  constructor () {
    super()
    this.buildYear = (new BuiltYearOptionsGenerator({})).generate()
  }

  /**
   * @param {object} schema
   * @return {void}
   */
  fromSchema (schema = {}): void {
    this.form = reactive({
      step1: [
        {
          groupName: '物件の種類',
          groupKey: 'property_type_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'property_type',
              type: 'radio',
              items: ['マンション一室', '一戸建て', '土地', 'ビル一室', '店舗・事務所・倉庫', 'マンション一棟', 'アパート一棟', 'ビル一棟', 'その他'],
              rules: 'required',
              requiredMarker: false
            }),
            new FormGroupItem({
              key: 'property_type_description',
              type: 'text',
              rules: 'description',
              target: 'property_type',
              display: false,
              note: 'その他を選択された方はこちらに入力してください<br>本サイトでの査定が可能なのは「宅地」のみとなります。田畑や山林等は査定対象外となりますので、予めご了承ください。',
              requiredMarker: false
            })
          ]
        },
        {
          groupName: '物件の所在地',
          groupKey: 'property_address_group',
          grouprequiredMarker: false,
          groupItems: [
            new FormGroupItem({
              name: '都道府県',
              key: 'property_prefecture',
              type: 'text',
              rules: 'required',
              readonly: true
            }),
            new FormGroupItem({
              name: '市区町村',
              key: 'property_town',
              type: 'select',
              rules: 'required'
            }),
            new FormGroupItem({
              name: '町字',
              key: 'property_street_address_1',
              type: 'text',
              rules: 'required',
              placeholder: '例）楽佐手町'
            }),
            new FormGroupItem({
              name: '番地',
              key: 'property_street_address_2',
              type: 'text',
              rules: 'required',
              placeholder: '例）1-1-1'
            }),
            new FormGroupItem({
              name: '建物名・号室',
              key: 'property_building_name',
              type: 'text',
              requiredMarker: false,
              placeholder: '例）カラフルマンション101号室'
            })
          ]
        },
        {
          groupName: '土地面積',
          groupKey: 'land_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'land_area',
              type: 'text',
              rules: 'landArea|numeric',
              requiredMarker: false,
              placeholder: '例）53.2'
            }),
            new FormGroupItem({
              key: 'land_area_unit',
              type: 'radio',
              items: ['平方メートル', '坪', 'わからない'],
              rules: 'required',
              requiredMarker: false
            })
          ]
        },
        {
          groupName: '建物面積・専有面積',
          groupKey: 'building_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'building_area',
              type: 'text',
              rules: 'buildingArea|numeric',
              note: '※ビル１室、マンション１室等は専有面積を入力してください。<br>※建物付きの土地は建物面積を入力してください。<br>※1階と2階の面積を足したものを入力してください',
              requiredMarker: false,
              placeholder: '例）53.2'
            }),
            new FormGroupItem({
              key: 'building_area_unit',
              type: 'radio',
              items: ['平方メートル', '坪', 'わからない'],
              rules: 'buildingAreaUnit',
              requiredMarker: false
            })
          ]
        },
        {
          groupName: '間取り',
          groupKey: 'layout_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'layout',
              type: 'radio',
              items: ['1R', '1K/1DK', '1LK/1LDK', '2K/2DK', '2LK/2LDK', '3K/3DK', '3LK/3LDK', '4K/4DK', '4LK/4LDK', '5K/5DK', '5LK/5LDK以上', 'わからない'],
              rules: 'layout',
              requiredMarker: false
            })
          ]
        },
        {
          groupName: '建物を建てた年',
          groupKey: 'built_year_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'built_year',
              type: 'select',
              items: [{ name: 'わからない' }].concat(this.buildYear),
              rules: 'builtYear',
              requiredMarker: false
            })
          ]
        }
      ],
      step2: [
        {
          groupName: '物件に住んでいるか',
          groupKey: 'living_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'living',
              type: 'radio',
              items: ['はい', 'いいえ'],
              rules: 'required',
              requiredMarker: false
            })
          ]
        },
        {
          groupName: '査定の理由',
          groupKey: 'reason_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'reason',
              type: 'radio',
              items: ['住み替え', '物件を相続したため', '住宅ローンの返済が厳しい', '不要物件の処分', '離婚', '転勤', '所有者が高齢', 'その他'],
              rules: 'required',
              requiredMarker: false
            }),
            new FormGroupItem({
              key: 'reason_description',
              type: 'text',
              rules: 'description',
              target: 'reason',
              display: false,
              note: 'その他を選択された方はこちらに入力してください',
              requiredMarker: false
            })
          ]
        },
        {
          groupName: '物件との関係',
          groupKey: 'relation_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'relation',
              type: 'radio',
              items: ['物件の名義人本人', '名義人の家族・親族', '共有名義', '物件所有者の了承を得た代理人', 'その他'],
              rules: 'required',
              requiredMarker: false
            }),
            new FormGroupItem({
              key: 'relation_description',
              type: 'text',
              rules: 'description',
              target: 'relation',
              display: false,
              note: 'その他を選択された方はこちらに入力してください',
              requiredMarker: false
            })
          ]
        },
        {
          groupName: '売却希望時期',
          groupKey: 'preferred_timing_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'preferred_timing',
              type: 'radio',
              items: ['出来るだけ早く', '1ヶ月以内', '3ヶ月以内', '1年以内', '時期未定'],
              rules: 'required',
              requiredMarker: false
            })
          ]
        },
        {
          groupName: '査定の方法',
          groupKey: 'quotation_way_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'quotation_way',
              type: 'radio',
              items: ['簡易査定', '訪問査定'],
              rules: 'required',
              requiredMarker: false
            })
          ]
        }
      ],
      step3: [
        {
          groupName: '依頼人情報',
          groupKey: 'user_info_group',
          grouprequiredMarker: false,
          groupItems: [
            new FormGroupItem({
              name: '姓',
              key: 'last_name',
              type: 'text',
              rules: 'required',
              autocomplete: 'family-name',
              placeholder: '例）楽佐手'
            }),
            new FormGroupItem({
              name: '名',
              key: 'first_name',
              type: 'text',
              rules: 'required',
              autocomplete: 'given-name',
              placeholder: '例）太郎'
            }),
            new FormGroupItem({
              name: 'せい',
              key: 'last_name_kana',
              type: 'text',
              rules: 'required',
              placeholder: '例）らくさて'
            }),
            new FormGroupItem({
              name: 'めい',
              key: 'first_name_kana',
              type: 'text',
              rules: 'required',
              placeholder: '例）たろう'
            }),
            new FormGroupItem({
              name: '電話番号',
              key: 'tel',
              type: 'tel',
              rules: 'required|tel',
              autocomplete: 'tel',
              placeholder: '0762921111'
            }),
            new FormGroupItem({
              name: '郵便番号（半角数字）',
              key: 'zip_code',
              type: 'text',
              rules: 'required|postalCode',
              autocomplete: 'postal-code',
              note: '※ハイフンなしで入力',
              placeholder: '例）9218002'
            }),
            new FormGroupItem({
              name: '都道府県',
              key: 'user_prefecture',
              type: 'text',
              rules: 'required',
              autocomplete: 'address-level1',
              placeholder: '例）石川県'
            }),
            new FormGroupItem({
              name: '群/市区町村',
              key: 'user_town',
              type: 'text',
              rules: 'required',
              autocomplete: 'address-level2',
              placeholder: '例）金沢市玉鉾'
            }),
            new FormGroupItem({
              name: '番地',
              key: 'user_house_number',
              type: 'text',
              rules: 'required',
              autocomplete: 'street-address',
              placeholder: '例）3-29'
            }),
            new FormGroupItem({
              name: '建物名・号室',
              key: 'user_building_name',
              type: 'text',
              requiredMarker: false,
              placeholder: '例）カラフルアパート303号室'
            }),
            new FormGroupItem({
              name: 'メールアドレス',
              key: 'email',
              type: 'email',
              rules: 'required|email',
              autocomplete: 'email',
              placeholder: '例）rakusate@colorfulcompany.co.jp'
            }),
            new FormGroupItem({
              name: 'メールアドレスの確認',
              key: 'email_confirmation',
              type: 'email',
              rules: 'required|emailConfirm',
              autocomplete: 'email'
            })
          ]
        },
        {
          groupName: '不動産会社を選択',
          groupKey: 'broker_account_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'broker_account_ids',
              type: 'checkbox',
              value: [],
              checked: true,
              rules: 'required',
              requiredMarker: false
            })
          ]
        },
        {
          groupName: 'プライバシーポリシーに同意',
          groupKey: 'agreement_group',
          groupRequiredMarker: true,
          groupItems: [
            new FormGroupItem({
              key: 'agreement',
              type: 'radio',
              items: ['同意する', '同意しない'],
              rules: 'agreement',
              requiredMarker: false
            })
          ]
        }
      ]
    })
    this.errors = reactive({
      step1: {},
      step2: {},
      step3: {}
    })
  }

  /**
   * flatten 1 level nested array
   * @param {Array} arr
   * @return {Array}
   */
  flatten (arr: any[]): any[] {
    return [].concat(...arr)
  }

  /**
   * @param {string} key
   * @return {object | undefined}
   */
  find (key: string): FormGroupItem|undefined {
    const items: any = []
    if (typeof key === 'undefined') { return undefined }

    // groupItems のみの Array を作る
    Object.keys(this.form).forEach((step) => {
      this.form[step].forEach((group) => {
        items.push(group.groupItems)
      })
    })

    return this.flatten(items).filter((item: FormGroupItem) => item.key === key)[0]
  }

  /**
   * @param {string} key
   * @return {object | undefined}
   */
  findByGroup (key: string): any {
    const groups: any = []
    if (typeof key === 'undefined') { return undefined }

    Object.keys(this.form).forEach(k => {
      this.form[k].forEach(group => {
        groups.push(group)
      })
    })
    return this.flatten(groups).filter(group => group.groupKey === key)[0]
  }

  /**
   * group化を解消したflatなitemを返す
   * @param {string} step
   * @return {Array<object>}
   */
  formItems (step: string): any[] {
    const items: any = []
    if (typeof this.form[step] === 'undefined') {
      throw new FromSchemaNotDefined()
    }

    this.form[step].forEach(group => {
      items.push(group.groupItems)
    })

    return this.flatten(items)
  }

  /**
   * @param {string} step
   * @return {boolean}
   */
  hasErrorByStep (step: string): boolean {
    const errors = this.errors[step]
    let valid = true

    if (Object.keys(errors).length === 0) {
      return false
    } else {
      Object.keys(errors).forEach((itemKey) => {
        if (!(Array.isArray(errors[itemKey]) && errors[itemKey].length === 0)) {
          valid = false
        }
      })
    }
    return valid
  }
}
