interface Wareki {
  startDate: Date
  startYear: number
  name: string
}

/**
 * 追加する場合は先頭に、全体が startYear 降順になるように
 */
const WarekiTable: Readonly<Record<string, Wareki>> = {
  reiwa: {
    startDate: new Date('2019-05-01'),
    startYear: 2019,
    name: '令和'
  },
  heisei: {
    startDate: new Date('1989-01-08'),
    startYear: 1989,
    name: '平成'
  },
  showa: {
    startDate: new Date('1926-12-25'),
    startYear: 1926,
    name: '昭和'
  }
}

class WarekiNotDefined extends Error {
  get name () { return 'WarekiNotDefined' }
}

export class WarekiConvertor {
  readonly WAREKI_OFFSET = 1

  /**
   * @param {number} year
   * @returns {boolean}
   */
  isGannen (year: number): boolean {
    return year === 1
  }

  /**
   * @param {number} year
   * @returns {string}
   */
  warekiNen (year: number): string {
    return this.isGannen(year) ? '元' : year.toString()
  }

  /**
   * @param {number} year
   * @returns {Wareki}
   */
  search (year: number): Wareki {
    const warekiName = Object.keys(WarekiTable).find((name) => {
      return year >= WarekiTable[name].startYear
    })

    if (warekiName) {
      return WarekiTable[warekiName]
    } else {
      throw new WarekiNotDefined(`year ${year}`)
    }
  }

  /**
   * @param {number} year
   * @returns {String}
   */
  convert (year: number): string {
    const wareki: Wareki = this.search(year)
    const nen = year - wareki.startYear + this.WAREKI_OFFSET

    return `${wareki.name}${this.warekiNen(nen)}年`
  }
}
