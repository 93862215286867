/**
 * quotation_order_form.ts の groupItemsのオブジェクトを生成するためのclass
 */
export class FormGroupItem {
  name: string
  key: string
  type: string
  value: any
  items: string[] | Array<{name: string}> | undefined
  rules: string
  target: string | undefined
  readonly: boolean
  display: boolean
  checked: true | undefined
  note: string | undefined
  requiredMarker: boolean
  autocomplete: string | undefined
  placeholder: string | undefined

  /**
   * @param {object} params
   * @param {string} params.name
   * @param {string} params.key
   * @param {string} params.type
   * @param {string} params.value
   * @param {Array} params.items
   * @param {string} params.rules
   * @param {string} params.target
   * @param {boolean} params.readonly
   * @param {boolean} params.display
   * @param {boolean} param.checked
   * @param {string} params.note
   * @param {boolean} params.requiredMarker
   * @param {string} params.autocomplete
   * @param {string} params.placeholder
   */
  constructor ({
    name = '',
    key,
    type,
    value = undefined,
    items = undefined,
    rules = '',
    target = undefined,
    readonly = false,
    display = true,
    checked = undefined,
    note = undefined,
    requiredMarker = true,
    autocomplete = undefined,
    placeholder = undefined
  }: {
    name?: string
    key: string
    type: string
    value?: any
    items?: string[] | Array<{name: string}>
    rules?: string
    target?: string
    readonly?: boolean
    display?: boolean
    checked?: true | undefined
    note?: string
    requiredMarker?: boolean
    autocomplete?: string
    placeholder?: string
  }) {
    this.name = name
    this.key = key
    this.type = type
    this.value = value
    this.items = items
    this.rules = rules
    this.target = target
    this.readonly = readonly
    this.display = display
    this.checked = checked
    this.note = note
    this.requiredMarker = requiredMarker
    this.autocomplete = autocomplete
    this.placeholder = placeholder
  }
}
