import type { FormObject } from '../form/form'

export default class ValidateBeforeSubmit {
  public readonly service: string
  private readonly form: FormObject

  /**
   * @param {object} params
   * @param {string} params.service
   * @param {FormObject} params.form
   */
  constructor ({
    service,
    form
  }) {
    this.service = service
    this.form = form
  }

  /**
   * @return {boolean}
   */
  execute (): boolean {
    const steps = this.formSteps(this.form.form)
    let valid = true

    steps.forEach(step => {
      if (!this.form.hasErrorByStep(step)) {
        valid = false
      }
    })
    return valid
  }

  /**
   * @param {any} form
   * @return {Array}
   */
  formSteps (form): string[] {
    return Object.keys(form)
  }
}
