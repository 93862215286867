export default class ValidationMessageBuilder {
  /**
   * @param {string} type
   * @return {string | undefined}
   */
  required (type): string {
    const errorMessages = {
      text: '入力してください',
      tel: '入力してください',
      email: '入力してください',
      radio: '選択してください',
      select: '選択してください',
      checkbox: '選択してください'
    }
    return typeof errorMessages[type] !== 'undefined' ? errorMessages[type] : '入力してください'
  }
}
