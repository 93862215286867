import { application } from './controllers/application.js'
import CvButtonController from './controllers/cv-button-controller.js'

const CvButtonControllerDomName = 'cvbutton'

;(() => {
  application.register(CvButtonControllerDomName, CvButtonController)
})()

// select の value が cache から restore されるタイミングが DOMContentLoaded よりも遅いので window.load に bind
window.addEventListener('load', () => {
  const controllers = document.querySelectorAll(`form[data-controller="${CvButtonControllerDomName}"]`)
  if (controllers.length > 0) {
    Array.from(controllers).map((controller) => resetSelectedValue(controller))
  }

  /**
   * @param {HTMLElement} controller
   * @return {void}
   */
  function resetSelectedValue (controller) {
    const options = Array.from(controller.querySelectorAll('option'))
    const selected = options.filter((option) => option.selected)[0]
    if (selected) {
      controller.dataset.cvbuttonSelectedValue = selected.value
    }
  }
})
