<!-- eslint-disable vue/no-mutating-props -->
<!-- 選択肢は [{ name: xxx }] である必要があります -->
<template>
  <select
    v-model="item.value"
    @change="$emit('changed', item)"
    :disabled="item.disabled"
  >
    <option
      v-for="option in item.items"
      :key="option.name"
      :value="option.name"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'FormItemSelect',
  props: ['item'],
  emits: ['changed']
}
</script>
