import queryString from 'query-string'
import { FormObject } from '../form/form'
import { TownOption, TownOptions } from '../lib/interfaces'

export default class SetPropertyTownValueUsecase {
  public readonly service: string
  private readonly form: FormObject
  private readonly slug: string
  private readonly townOptions: TownOptions

  /**
   * @param {object} params
   * @param {string} params.service
   * @param {FormObject} params.form
   * @param {string} params.query
   * @param {Array<any>} townOptions
   * @param params.townOptions
   */
  constructor ({
    service,
    form,
    query,
    townOptions
  }) {
    this.service = service
    this.form = form
    this.slug = this.parseQueryTown(query)
    this.townOptions = townOptions
  }

  execute (): void {
    const town = this.findTown(this.slug)
    if (typeof town !== 'undefined') {
      const propertyTown = this.form.find('property_town')
      propertyTown.value = town.name
      propertyTown.disabled = true
    }
  }

  /**
   * @param {string} slug
   * @return {object}
   */
  findTown (slug: string): any {
    return this.townOptions.filter((town: TownOption) => town.slug === slug)[0]
  }

  /**
   * @param {string} query
   * @return {string}
   */
  parseQueryTown (query: string): any {
    const towns = queryString.parse(query).town
    return Array.isArray(towns)
      ? towns[0]
      : towns
  }
}
