import { Controller } from '@hotwired/stimulus'

export default class CvButtonController extends Controller {
  static values = { selected: String, townHasBroker: Object } // townHasBroker: { townSlug: boolean, ... }
  static targets = ['town', 'formlink', 'message']
  static classes = ['loading', 'error']

  update () {
    const selectedTown = this.townTarget.value
    if (!selectedTown) {
      this.initializeState()
    } else {
      if (this.townHasBrokerValue[selectedTown]) {
        this.enableState()
      } else {
        this.disableState()
      }
    }
  }

  initializeState () {
    this.formlinkTarget.classList.remove(this.loadingClass)
    this.messageTarget.classList.remove(this.errorClass)
  }

  enableState () {
    this.formlinkTarget.classList.add(this.loadingClass)
    this.messageTarget.classList.remove(this.errorClass)
  }

  disableState () {
    this.formlinkTarget.classList.remove(this.loadingClass)
    this.messageTarget.classList.add(this.errorClass)
  }

  selectedValueChanged () {
    this.update()
  }
}
