import queryString from 'query-string'

/**
 * @param {string} search
 * @return {string|undefined|null}
 */
export default function (search: any): string|undefined|null {
  if (typeof search === 'undefined') { return undefined }
  const towns = queryString.parse(search).town
  return Array.isArray(towns)
    ? towns[0]
    : towns
}
