import type { FormObject } from '../form/form.d'
import { FormGroupItem } from '../form/form_group_item'
import FormValidator from '../form/validator'

declare function ValidatorCallback (v: any, f: any, item: any): boolean

class ValidateRuleNotDefined extends Error { get name (): string { return 'ValidateRuleNotDefined' } }

/**
 * Don't call commit / dispatch directory for store from this class
 * use actions
 */
export default class ValidateFormUsecase {
  public readonly service: string
  private readonly form: FormObject
  private readonly validator: FormValidator

  /**
   * @param {object} params
   * @param {string} params.service
   * @param {FormObject} params.form
   * @param {FormValidator} params.validator
   */
  constructor ({
    service,
    form,
    validator = new FormValidator()
  }) {
    this.service = service
    this.form = form
    this.validator = validator
  }

  /**
   * @param {string} step
   * @return {void}
   */
  execute (step: string): void {
    this.form.form[step].forEach((formItemGroup) => {
      formItemGroup.groupItems.forEach((item: FormGroupItem) => {
        this.form.errors[step][item.key] = []
        if (item.rules !== '') {
          item.rules.split('|').forEach((rule) => {
            const ret = this.onValidated(this.validator[rule], item)
            if (ret !== true) {
              this.form.errors[step][item.key].push(ret)
            }
          })
        }
      })
    })
  }

  /**
   * @param {Function} callback
   * @param {any} item
   * @return {boolean|string}
   */
  onValidated (callback: typeof ValidatorCallback, item: any): boolean|string {
    if (typeof callback !== 'function') {
      throw new ValidateRuleNotDefined()
    }
    return callback.call(this.validator, this.form, item)
  }
}
