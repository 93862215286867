/**
 * @param {object} param
 * @param {string} param.appSelector
 * @param {event} param.event
 */
export function alertWhenBrowserBack ({ appSelector, event }): void {
  if (appIsMounted(appSelector)) {
    browserBackAlert(event)
  }
}

/**
 * @param {string} appSelector
 * @return {boolean}
 */
function appIsMounted (appSelector): boolean {
  return document.querySelector(appSelector) !== null
}

/**
 * @param {any} event
 * @return {void}
 */
function browserBackAlert (event): void {
  const submitButton = event.target.querySelector('.form__button-submit')
  if (!(isSubmitting(submitButton))) {
    event.preventDefault()
    event.returnValue = ''
  }
}

/**
 * @param {any} element
 * @return {boolean}
 */
function isSubmitting (element?: HTMLElement): boolean {
  return typeof element !== 'undefined' && element.classList.contains('submitting')
}
