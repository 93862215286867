<template>
  <div v-show="openGuidance" class="form__modal-overlay">
    <div class="form__modal">
      <p class="form__modal-title">査定依頼の送信に失敗しました</p>
      <p class="form__modal-text">大変申し訳ございませんが、もう一度送信ボタンを押してください。<br>再度失敗して送信できない場合は、お手数ですが下記お問い合わせ先までご連絡ください。</p>
      <p class="form__modal-tel">お問い合わせ先:<a href="tel:076-292-1111">076-292-1111</a></p>
      <button class="form__modal-button" @click="$emit('closeGuidance')">閉じる</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServerError',
  props: ['openGuidance'],
  emits: ['closeGuidance']
}
</script>
