import loadGtag from './gtag-loader'
import GtagMeasurementIdInitializer from './gtag-measurement-id-initializer'
import AnalyticsPageViewSender from './analytics-pageview-sender'

/**
 * @param {any} document
 * @param {any} window
 * @return {any}
 */
function createAnalyticsPageViewSender ({ document, window }) {
  const gtag = loadGtag({ document, window })
  const initializer = new GtagMeasurementIdInitializer(window.location.hostname)
  initializer.setup(gtag)

  return new AnalyticsPageViewSender(gtag, initializer.measurementId())
}

export { createAnalyticsPageViewSender }
