<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-show="item.display">
    <p v-html="item.note" v-show="item.note" class="form__item-note"></p>
    <input
      :name="item.name"
      :type="item.type"
      :value="item.value"
      :readonly="item.readonly"
      :autocomplete="item.autocomplete"
      :placeholder="item.placeholder"
      v-model="item.value"
      @change="$emit('changed', item)" />
  </div>
</template>

<script>
export default {
  name: 'FormItemText',
  props: ['item'],
  emits: ['changed']
}
</script>
