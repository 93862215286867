import ky from 'ky-universal'
import type { FormObject } from '../form/form.d'

export default class FormPostUsecase {
  public readonly service: string
  private readonly token: string
  private readonly form: FormObject
  private readonly store: any
  private readonly httpclient: any

  /**
   * @param {object} params
   * @param {string} params.service
   * @param {FormObject} params.form
   * @param params.token
   * @param params.httpclient
   */
  constructor ({
    service,
    token,
    form,
    httpclient = ky
  }: {
    service: string
    token: string
    form: FormObject
    httpclient: any
  }) {
    this.service = service
    this.token = token
    this.form = form
    this.httpclient = httpclient
  }

  /**
   * @return {Promise<any>}
   */
  async execute (): Promise<any> {
    let res
    try {
      res = await this.httpclient.post('order', this.params())
    } catch (error) {
      // TODO: error の際のデータ取得と response への設定フォーマットは仮
      res = { status: error.status, errors: error.message }
    }
    return res
  }

  params (): any {
    return {
      // TODO: location.origin は usecase の外から受け取るように変更する
      prefixUrl: window.location.origin,
      json: this.buildData()
    }
  }

  /**
   * //TODO: 組み立ての処理は仮
   *
   * @return {object}
   */
  buildData (): any {
    const data = {
      authenticity_token: this.token,
      order: {}
    }

    Object.keys(this.form.form).forEach(step => {
      const ret = {}
      this.form.form[step].forEach(group => {
        group.groupItems.forEach(item => {
          ret[item.key] = item.value
        })
      })
      data.order[step] = ret
    })

    return data
  }
}
