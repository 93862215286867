<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    v-for="form_item in item.items"
    :key="form_item"
  >
    <label>
      <input
        :name="item.key"
        :type="item.type"
        v-model="item.value"
        :value="form_item"
        @change="$emit('changed', item)"
      >
        {{ form_item }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'FormItemRadio',
  props: ['item'],
  emits: ['changed']
}
</script>
