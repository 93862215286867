/**
 * @param {any} to
 * @param {any} from
 * @return {boolean}
 */
export function fromTopToForm (to: any, from: any): boolean {
  if (typeof from.name === 'undefined' && to.name === 'step1') {
    return true
  } else {
    return false
  }
}
