import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { RouteLocationNormalized, RouteMeta } from 'vue-router/dist/vue-router.d'
import { Prefecture, TownOptions } from './interfaces'
import { createAnalyticsPageViewSender } from '../lib/google'
import { fromTopToForm } from '../lib/from_step_detector'

/**
 * rakuste独自のroutingのmeta情報の型
 */
interface RakusateRouteMeta extends RouteMeta {
  step: string
}

/**
 * prefecture情報を持つJSON文字列を受け取り、県の情報を返す
 *
 * 受け取るデータは以下の通り
 * { "id": 1, "name": "石川県" }
 *
 * TODO: existPrefecture() という名前はいかにも boolean を返しそうなので見直しが必要
 *
 * @param {string | undefined} prefecture
 * @return {object}
 */
function existPrefecture (prefecture): Prefecture {
  if (typeof prefecture === 'undefined') {
    throw new Error('data-property-prefecture is undefiend!')
  } else {
    return JSON.parse(prefecture)
  }
}

/**
 * town情報の定義をJSON文字列からobjectにして返す
 *
 * 受け取るデータは以下の通り
 * [ { "id": 18, "name": "穴水町", "slug": "anamizu" }, {..}, .. ]
 *
 * TODO: existTownOptions() という名前はいかにも boolean を返しそうなので見直しが必要
 *
 * @param {string | undefined} townOptions
 * @return {object}
 */
function existTownOptions (townOptions): TownOptions {
  if (typeof townOptions === 'undefined') {
    throw new Error('data-property-town-options is undefiend!')
  } else {
    return JSON.parse(townOptions)
  }
}

/**
 * @param {object} param
 * @param {any} param.App
 * @param {any} param.dom
 * @param {any} param.component
 */
export function initializeVueApp ({ App, dom, component }): void {
  const baseTitle = document.title
  const pageViewSender = createAnalyticsPageViewSender({ document, window })
  const router = createRouter({
    history: createWebHistory('/order/'),
    routes: [
      { name: 'step1', path: '/', component: component, meta: { step: '(STEP1)' } },
      { name: 'step2', path: '/2', component: component, meta: { step: '(STEP2)' } },
      { name: 'step3', path: '/3', component: component, meta: { step: '(STEP3)' } }
    ],
    scrollBehavior (to, from, savedPosition) {
      return { top: 0 }
    }
  })
  router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const title = `${baseTitle}${(to.meta as RakusateRouteMeta).step} | ラクサテ produced by 家づくりナビ`
    document.title = title
    if (!fromTopToForm(to, from)) {
      pageViewSender.send(title, location.href)
    }
  })
  createApp(App, {
    token: dom.dataset.token,
    propertyPrefecture: existPrefecture(dom.dataset.propertyPrefecture),
    propertyTownOptions: existTownOptions(dom.dataset.propertyTownOptions)
  }).use(router).mount(dom)
}
