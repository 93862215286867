<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <label
      v-for="checkbox_item in item.items"
      :key="checkbox_item"
      :value="checkbox_item">
      <input
        :type="item.type"
        :value="checkbox_item.id"
        v-model="item.value"
        @change="$emit('changed', item)"
      >
      <span :for="checkbox_item">
        {{ checkbox_item.name }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FormItemCheckbox',
  props: ['item'],
  emits: ['changed']
}
</script>
