<template>
  <component
    :is='type'
    :item="item" />
</template>

<script>
import Text from './FormItemText.vue'
import Tel from './FormItemTel.vue'
import Email from './FormItemEmail.vue'
import Checkbox from './FormItemCheckbox.vue'
import Radio from './FormItemRadio.vue'
import Select from './FormItemSelect.vue'

export default {
  name: 'FormItemBase',
  props: ['item'],
  components: {
    Text, // eslint-disable-line vue/no-reserved-component-names
    Tel,
    Email,
    Checkbox,
    Radio,
    Select // eslint-disable-line vue/no-reserved-component-names
  },
  computed: {
    type () {
      return this.item.type
    }
  }
}
</script>
