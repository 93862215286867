import type { FormObject } from '../form/form.d'

class StepFormSchemaNotDefiend extends Error { get name (): string { return 'StepFormSchemaNotDefiend' } }
/**
 * Don't call commit / dispatch directory for store from this class
 * use actions
 */
export default class IsValidFormUsecase {
  public readonly service: string
  private readonly form: FormObject

  /**
   * @param {object} params
   * @param {string} params.service
   * @param {FormObject} params.form
   */
  constructor ({
    service,
    form
  }) {
    this.service = service
    this.form = form
  }

  /**
   * @param {object} errors
   * @return {boolean}
   */
  isErrorInitilized (errors: any): boolean {
    return Object.keys(errors).length === 0
  }

  /**
   * emptyなarrayの場合はvalid
   *
   * @param {Array<string> | undefined} errorItem
   * @return {boolean}
   */
  isItemValid (errorItem: string[] | undefined): boolean {
    return Array.isArray(errorItem) && errorItem.length === 0
  }

  /**
   * @param {string} step
   * @return {boolean}
   */
  execute (step: string): boolean {
    let valid = true

    if (typeof this.form.form[step] === 'undefined') {
      throw new StepFormSchemaNotDefiend()
    }

    if (this.isErrorInitilized(this.form.errors[step])) {
      valid = false
    } else {
      this.form.formItems(step)
        .forEach(item => {
          if (!this.isItemValid(this.form.errors[step][item.key])) {
            valid = false
          }
        })
    }
    return valid
  }
}
