import ky from 'ky-universal'
import { FormObject } from '../form/form.d'

export default class FetchFormItemsUsecase {
  public readonly service: string
  private readonly form: FormObject
  private readonly httpclient

  /**
   * @param {object} params
   * @param {string} params.service
   * @param {FormObject} params.form
   * @param params.httpclient
   */
  constructor ({
    service,
    form,
    httpclient = ky
  }) {
    this.service = service
    this.form = form
    this.httpclient = httpclient
  }

  execute (): any {
    return this.form.fromSchema('')
  }

  endpoint (): string {
    return '/api/form_items.json'
  }

  async fetch (): Promise<any> {
    return {}
  }
}
