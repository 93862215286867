import { WarekiConvertor } from '../lib/wareki-convertor'

/**
 * 建物を建てた年の選択肢を生成
 */
export class BuiltYearOptionsGenerator {
  readonly startYear: number
  readonly endYear: number
  readonly convertor: WarekiConvertor

  /**
   * @param {number} startYear
   * @param {number} endYear
   */
  constructor ({
    startYear = 1930,
    endYear = (new Date()).getFullYear()
  }: {
    startYear?: number
    endYear?: number
  }) {
    this.startYear = startYear
    this.endYear = endYear
    this.convertor = new WarekiConvertor()
  }

  /**
   * @param {number} year
   * @returns {object}
   */
  buildOption (year: number): object {
    return {
      name: `${year}年(${this.convertor.convert(year)})`
    }
  }

  /**
   * @returns {Array<object>}
   */
  generate (): object[] {
    const buildYears = [{ name: `${this.startYear}年(${this.convertor.convert(this.startYear)})以前` }]

    for (let i = 1; i <= (this.endYear - this.startYear); i++) {
      buildYears.push(this.buildOption(this.startYear + i))
    }

    return buildYears
  }
}
