import type { FormObject } from '../form/form.d'

export default class SwitchRequiredMarkerUsecase {
  public readonly service: string
  private readonly form: FormObject

  constructor ({
    service,
    form
  }) {
    this.service = service
    this.form = form
  }

  execute (item: any): void {
    if (item.key === 'property_type') {
      this.layoutGroupMarker(item)
      this.builtYearGroupMarker(item)
      this.buildingGroupMarker(item)
    }
  }

  layoutGroupMarker (item: any): void {
    const layoutGroup = this.form.findByGroup('layout_group')
    if (item.value === 'マンション一室' || item.value === '一戸建て') {
      layoutGroup.groupRequiredMarker = true
    } else {
      layoutGroup.groupRequiredMarker = false
    }
  }

  builtYearGroupMarker (item: any): void {
    const builtYearGroup = this.form.findByGroup('built_year_group')
    if (!(item.value === '土地' || item.value === 'その他')) {
      builtYearGroup.groupRequiredMarker = true
    } else {
      builtYearGroup.groupRequiredMarker = false
    }
  }

  buildingGroupMarker (item: any): void {
    const buildingGroup = this.form.findByGroup('building_group')
    if (!(item.value === '土地' || item.value === 'その他')) {
      buildingGroup.groupRequiredMarker = true
    } else {
      buildingGroup.groupRequiredMarker = false
    }
  }
}
