import ky from 'ky-universal'
import queryString from 'query-string'
import type { FormObject } from '../form/form'
import { FormGroupItem } from '../form/form_group_item'

interface BrokerAccount {
  id: number
  name: string
}

interface BrokerAccountAPIResponse {
  items: BrokerAccount[]
}

export default class FetchAndUpdateBrokerAccountOptionsUsecase {
  public readonly service: string
  private readonly slug: string|null|undefined
  private readonly form: FormObject
  private readonly httpclient

  /**
   * @param {object} params
   * @param {String} params.service
   * @param {String} params.query
   * @param {FormObject} params.form
   */
  constructor ({
    service,
    form,
    query,
    httpclient = ky
  }) {
    this.service = service
    this.form = form
    this.slug = this.parseQueryTown(query)
    this.httpclient = httpclient
  }

  /**
   *
   * @param {any | undefined} item
   */
  async execute (item: any): Promise<void> {
    const slug = this.hasSlug(item)
    if (typeof slug === 'string') {
      await this.updateBroker(slug)
    }
  }

  /**
   *
   * @param {string} slug
   */
  async updateBroker (slug: string): Promise<void> {
    const brokerAccounts = await this.fetch(slug)
    const formItem = this.form.find('broker_account_ids')
    formItem.items = brokerAccounts.items // FIXME そもそも items は type が合っていないように見える
    this.precheckWhenCheckedTrue(formItem, brokerAccounts)
  }

  /**
   * checkbox が checked: true の設定だった場合、送信する value に id を全部セットする
   *
   * @param {FormGroupItem} formGroupItem
   * @param {BrokerAccountAPIResponse} brokerAccounts
   */
  precheckWhenCheckedTrue (formGroupItem: FormGroupItem, brokerAccounts: BrokerAccountAPIResponse) {
    if (brokerAccounts.items && brokerAccounts.items.length > 0 && formGroupItem.checked) {
      brokerAccounts.items.forEach((e: BrokerAccount) => formGroupItem.value.push(e.id))
    }
  }

  /**
   * FIXME: このclass外にすでにあるものを再実装してませんか？
   *
   * @param {string} query
   * @return {String}
   */
  parseQueryTown (query: string): string|null {
    const towns = queryString.parse(query).town
    if (Array.isArray(towns)) {
      return towns[0]
    } else {
      return towns
    }
  }

  /**
   * @param {any} item
   * @return {string | undefined}
   */
  hasSlug (item?: FormGroupItem): string|undefined|null {
    if (typeof item === 'undefined') {
      if (typeof this.slug === 'undefined') {
        return undefined
      } else {
        return this.slug
      }
    } else {
      return item.key === 'property_town' ? this.valueToSlug(item.value) : undefined
    }
  }

  /**
   * @param {string} townName
   * @return {Array}
   */
  valueToSlug (townName: string): string {
    const formItem = this.form.find('property_town')
    return formItem.items.filter(item => item.name === townName)[0].slug
  }

  /**
   * @param {string} slug
   * @return {Promise<BrokerAccountAPIResponse>}
   */
  async fetch (slug: string): Promise<BrokerAccountAPIResponse> {
    try {
      return await this.getBroker(slug)
    } catch (e) {
      if (e.response.status === 404) {
        return { items: [] }
      } else {
        throw e
      }
    }
  }

  /**
   * @param {string} slug
   * @return {Promise<BrokerAccountAPIResponse>}
   */
  async getBroker (slug: string): Promise<BrokerAccountAPIResponse> {
    return (await this.httpclient.get(`/api/broker_accounts?town=${slug}`, { throwHttpErrors: true })).json()
  }
}
