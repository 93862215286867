<!-- eslint-disable vue/no-mutating-props -->
<template>
  <input
    :name="item.name"
    :type="item.type"
    :value="item.value"
    :placeholder="item.placeholder"
    v-model="item.value"
    @change="$emit('changed', item)" />
</template>

<script>
export default {
  name: 'FormItemTel',
  props: ['item'],
  emits: ['changed']
}
</script>
