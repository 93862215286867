<template>
  <div v-if="sending" class="form__modal-overlay">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'SendingLoader',
  props: ['sending']
}
</script>
