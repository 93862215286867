<script>
import Basil from 'basil.js'

import ValidateFormUsecase from '../usecases/validate_form_usecase.ts'
import IsValidFormUsecase from '../usecases/is_valid_form_usecase.ts'
import PostFormUsecase from '../usecases/post_form_usecase.ts'
import FetchFormItemsUsecase from '../usecases/fetch_form_items_usecase.ts'
import RestoreStoreUsecase from '../usecases/restore_store_usecase.ts'
import FetchAndUpdateBrokerAccountOptionsUsecase from '../usecases/fetch_and_update_broker_account_options_usecase.ts'
import SetDefaultPrefectureUsecase from '../usecases/set_default_prefecture_usecase'
import SetPropertyTownOptionsUsecase from '../usecases/set_property_town_options_usecase'
import SetPropertyTownValueUsecase from '../usecases/set_property_town_value_usecase'
import SwitchRequiredMarkerUsecase from '../usecases/switch_required_marker_usecase'
import FetchPrefectureAndTownUsecase from '../usecases/fetch_prefecture_and_town_usecase'
import ValidateBeforeSubmit from '../usecases/validate_before_submit_usecase'

import QuotationOrderForm from '../form/quotation_order_form.ts'
import queryStringTown from '../form/query_string_town'

class ServiceNotDefined extends Error { get name () { return 'ServiceNotDefined' } }

const quotationOrderForm = new QuotationOrderForm()

export default {
  template: '',
  name: 'FormContainerBase',
  props: ['token', 'propertyPrefecture', 'propertyTownOptions'],
  data () {
    return {
      openGuidance: false,
      sending: false,
      errorType: undefined,
      submitting: false
    }
  },
  async created () {
    if (typeof this.service === 'undefined') {
      throw new ServiceNotDefined()
    } else {
      this.initUsecases()
      await this.fetchFormItemsUsecase.execute()
      await this.restoreFormUsecase.execute()
      await this.FetchAndUpdateBrokerAccountOptionsUsecase.execute()
      this.setDefaultPrefectureUsecase.execute()
      this.SetPropertyTownOptionsUsecase.execute()
      this.SetPropertyTownValueUsecase.execute()
    }
  },
  computed: {
    /** @return {object} */
    form () {
      return quotationOrderForm.form
    },
    /** @return {object} */
    errors () {
      return quotationOrderForm.errors
    },
    valid () {
      return typeof this.$route.name !== 'undefined' ? this.isValidFormUsecase.execute(this.$route.name) : false
    },
    prevRouteName () {
      switch (this.$route.name) {
        case 'step2': return 'step1'
        case 'step3': return 'step2'
        default: return undefined
      }
    },
    nextRouteName () {
      switch (this.$route.name) {
        case 'step2': return 'step3'
        case 'step3': return undefined
        default: return 'step2'
      }
    }
  },
  methods: {
    initUsecases () {
      this.fetchFormItemsUsecase = new FetchFormItemsUsecase({
        service: this.service,
        form: quotationOrderForm
      })
      this.validateFormUsecase = new ValidateFormUsecase({
        service: this.service,
        form: quotationOrderForm
      })
      this.postFormUsecase = new PostFormUsecase({
        service: this.service,
        token: this.token,
        form: quotationOrderForm
      })
      this.restoreFormUsecase = new RestoreStoreUsecase({
        service: this.service,
        form: quotationOrderForm,
        storage: Basil
      })
      this.isValidFormUsecase = new IsValidFormUsecase({
        service: this.service,
        form: quotationOrderForm
      })
      this.FetchAndUpdateBrokerAccountOptionsUsecase = new FetchAndUpdateBrokerAccountOptionsUsecase({
        service: this.service,
        query: location.search,
        form: quotationOrderForm
      })
      this.setDefaultPrefectureUsecase = new SetDefaultPrefectureUsecase({
        service: this.service,
        form: quotationOrderForm,
        prefecture: this.propertyPrefecture
      })
      this.SetPropertyTownOptionsUsecase = new SetPropertyTownOptionsUsecase({
        service: this.service,
        form: quotationOrderForm,
        townOptions: this.propertyTownOptions
      })
      this.SetPropertyTownValueUsecase = new SetPropertyTownValueUsecase({
        service: this.service,
        form: quotationOrderForm,
        query: location.search,
        townOptions: this.propertyTownOptions
      })
      this.switchRequiredMarkerUsecase = new SwitchRequiredMarkerUsecase({
        service: this.service,
        form: quotationOrderForm
      })
      this.fetchPrefectureAndTownUsecase = new FetchPrefectureAndTownUsecase({
        service: this.service,
        form: quotationOrderForm
      })
      this.validateBeforeSubmit = new ValidateBeforeSubmit({
        service: this.service,
        form: quotationOrderForm
      })
    },
    async changed (item) {
      await this.fetchPrefectureAndTownUsecase.execute(item)
      this.validateFormUsecase.execute(this.$route.name)
      await this.FetchAndUpdateBrokerAccountOptionsUsecase.execute(item)
      this.switchRequiredMarkerUsecase.execute(item)
    },
    // TODO: POSTエラーの時の画面
    async submit () {
      if (!this.validateBeforeSubmit.execute()) {
        this.errorType = 'NotValidError'
        this.openGuidance = true
        return
      }
      this.sending = true
      const res = await this.postFormUsecase.execute()
      this.sending = false
      if (res.status === 201) {
        this.submitting = true
        this.$nextTick(function () {
          location.href = `thankyou?town=${queryStringTown(location.search)}`
        })
      } else {
        this.errorType = 'ServerError'
        this.openGuidance = true
      }
    },
    closeGuidance () {
      this.openGuidance = false
    },
    prev () {
      this.$router.replace({ name: this.prevRouteName, query: { town: queryStringTown(location.search) } })
    },
    next () {
      this.$router.replace({ name: this.nextRouteName, query: { town: queryStringTown(location.search) } })
    }
  }
}
</script>
