import { FormObject } from '../form/form'

export default class SetPropertyTownOptionsUsecase {
  public readonly service: string
  private readonly form: FormObject
  private readonly townOptions: any[]

  /**
   * @param {object} params
   * @param {String} params.service
   * @param {FormObject} params.form
   * @param {Array<object>} townOptions
   */
  constructor ({
    service,
    form,
    townOptions
  }) {
    this.service = service
    this.form = form
    this.townOptions = townOptions
  }

  execute (): void {
    const propertyTown = this.form.find('property_town')
    propertyTown.items = this.townOptions
  }
}
