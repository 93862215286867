<template>
  <div class="form">
    <div class="form__step">
      <FormStepNavi active="step1"><p><span>1</span>物件情報</p></FormStepNavi>
      <FormStepNavi active="step2"><p><span>2</span>査定に関する情報</p></FormStepNavi>
      <FormStepNavi active="step3"><p><span>3</span>依頼人情報</p></FormStepNavi>
    </div>
    <ErrorGuidance
      :openGuidance="openGuidance"
      :errorType="errorType"
      @closeGuidance="$emit('closeGuidance')">
    </ErrorGuidance>
    <SendingLoader :sending="sending"></SendingLoader>
    <p class="form__note font-semibold">以下の内容を入力してください。<span>*</span>は必須項目です。</p>
    <p class="form__note">※前のページに戻る際は、ページ下部の「戻る」ボタンを押してください</p>
    <div>
      <div
        v-for="formItemGroup in form"
        :key="formItemGroup.groupName"
        class="form__group"
      >
        <p class="form__title">{{ formItemGroup.groupName }}
          <span v-show="formItemGroup.groupRequiredMarker">*</span>
        </p>
        <div :class="`${formItemGroup.groupKey}`">
          <div
            v-for="groupItem in formItemGroup.groupItems"
            :key="groupItem"
            class="form__item"
            :class="`${groupItem.key}_item`"
          >
            <p class="form__item-name">{{ groupItem.name }}
              <span v-show="groupItem.requiredMarker">*</span>
            </p>
            <div>
              <dd :class="`${groupItem.key}`">
                <FormItemBase
                  :item="groupItem"
                  @changed="changed"
                />
              </dd>
            </div>
            <dd
              class="form__error"
              v-for="error in errors[groupItem.key]"
              :key="error"
            >
              <span>{{error}}</span>
            </dd>
          </div>
        </div>
      </div>
    </div>
    <div class="form__button-area">
      <span v-if="typeof prevRouteName != 'undefined'">
        <button
          class="form__button form__button-prev"
          @click="$emit('prev')">戻る
        </button>
      </span>
      <span v-if="typeof nextRouteName != 'undefined'">
        <button
          class="form__button form__button-next"
          v-bind:disabled="!valid"
          @click="$emit('next')">次へ
        </button>
      </span>
      <span v-if="$route.name == 'step3'">
        <button
          class="form__button form__button-submit"
          v-bind:class="{ submitting: submitting }"
          v-bind:disabled="!valid"
          @click="$emit('submit')">見積もりを依頼する
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import FormItemBase from './FormItemBase.vue'
import FormStepNavi from './FormStepNavi.vue'
import ErrorGuidance from './ErrorGuidance.vue'
import SendingLoader from './SendingLoader.vue'

export default {
  name: 'FormStepBase',
  props: ['form', 'errors', 'prevRouteName', 'nextRouteName', 'valid', 'openGuidance', 'sending', 'errorType', 'submitting'],
  components: { FormItemBase, FormStepNavi, ErrorGuidance, SendingLoader },
  emits: ['prev', 'next', 'submit', 'changed', 'closeGuidance'],
  methods: {
    changed (item) {
      this.$emit('changed', item)
    }
  }
}
</script>
