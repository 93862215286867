import ValidationMessageBuilder from './validationMessageBuilder'
import { FormGroupItem } from './form_group_item'

/**
 * エラーメッセージがあればそのまま文字列を、なければ true を返すという不思議な仕様を満たすためだけの関数
 *
 * しかも引数に来るのが required() の戻り値であり、本来は string|true
 * なのだが、テストコードが string|boolean を前提にしているため、正し
 * く string|true にしてしまうとテストコードが動かないため、仕方なく引
 * 数に boolean を受け付けている。これを呼び出しているメソッドの戻り値
 * もそのままの type にしつつ JSDoc コメントは正しいものにしてある。
 *
 * @param {string|true} value
 * @returns
 */
function stringOrTrue (value: string|boolean): string|boolean {
  return typeof value === 'string'
    ? ((value.length > 0) ? value : true)
    : true
}

/**
 * FIXME: validator という役割なんだけど、各メソッドは rule として呼び
 * 出されるものと実際の validation ロジックとしての役割が混ざっており、
 * この class の中身に注目した場合に非常に分かりにくくなっています。
 *
 * 例えば required はロジックとしては required かどうかではなく
 * fulfilled かどうかのロジックなっているので、このコードを読んだ際に
 * 違和感を覚えます。
 */
export default class FormValidator {
  private readonly validationMessageBuilder: any

  constructor () {
    this.validationMessageBuilder = new ValidationMessageBuilder()
  }

  /**
   * @param value
   * @return {boolean}
   */
  isEmptyValue (value: any): boolean {
    return value === null || value === undefined || String(value).trim().length === 0
  }

  /**
   * @param {unknown} arr
   * @return {boolean}
   */
  isEmptyArray (arr: unknown): boolean {
    return Array.isArray(arr) && arr.length === 0
  }

  /**
   * 必須項目が入力されているか検証する
   *
   * @param {any} form
   * @param item
   * @return {string|true}
   */
  required (form: any, item: FormGroupItem): string|boolean {
    if (
      this.isEmptyValue(item.value) ||
      this.isEmptyArray(item.value) ||
      item.value === false
    ) {
      return this.validationMessageBuilder.required(item.type)
    }
    return true
  }

  /**
   * メールアドレスの形式が正しいか検証する
   *
   * @param {any} form
   * @param item
   * @return {string|true}
   */
  email (form: any, item: FormGroupItem): string|boolean {
    const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (typeof item.value === 'string' && item.value.match(emailRegExp) === null) {
      return 'メールアドレスの形式が正しくありません'
    }
    return true
  }

  /**
   * 電話番号の形式が正しいか検証する
   *
   * @param {any} form
   * @param item
   * @return {string|true}
   */
  tel (form: any, item: FormGroupItem): string|boolean {
    const telRegExp = /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/
    if (typeof item.value === 'string' && item.value.match(telRegExp) === null) {
      return '電話番号の形式が正しくありません'
    }
    return true
  }

  /**
   * 「メールアドレス」と「メールアドレス確認」が一致するか検証する
   *
   * @param {any} form
   * @param item
   * @return {string|true}
   */
  emailConfirm (form: any, item: FormGroupItem): string|boolean {
    let error: string|true = ''
    const email = form.find('email')
    if (email.value !== item.value) {
      error = 'メールアドレスが一致しません'
    }
    return stringOrTrue(error)
  }

  /**
   * プライバシーポリシーに同意しているか検証する
   *
   * @param {any} form
   * @param {any} item
   * @return {string|true}
   */
  agreement (form: any, item: any): string|boolean {
    if (item.value !== '同意する') {
      return 'プライバシーポリシーに同意してください'
    }
    return true
  }

  /**
   * 選択肢で「その他」が選ばれている場合は、自由記述欄が入力されているか検証する
   *
   * FIXME: 副作用アリの名前でもないし戻り値を重視する名前でもないのでも役割が不明瞭
   *
   * @param {any} form
   * @param {any} item
   * @return {string|true}
   */
  description (form: any, item: any): string|boolean {
    let error: string|boolean = ''
    if (form.find(item.target).value === 'その他') {
      item.display = true
      error = this.required(form, item)
    } else {
      item.display = false
      item.value = ''
    }
    return stringOrTrue(error)
  }

  /**
   * 「土地面積」の単位が「わからない」を選択されている場合以外は、値が入力されているか検証する
   *
   * @param {any} form
   * @param {any} item
   * @return {string|true}
   */
  landArea (form: any, item: any): string|boolean {
    const landAreaUnit = form.find('land_area_unit')
    let error: string|boolean = ''
    if (landAreaUnit.value === 'わからない') {
      item.display = false
      item.value = ''
    } else {
      item.display = true
      error = this.required(form, item)
    }
    return stringOrTrue(error)
  }

  /**
   * 物件の種類が「土地」もしくは「その他」の場合はtrueを返す
   *
   * @param {any} form
   * @return {boolean}
   */
  buildingAreaRequired (form: any): boolean {
    const propertyType = form.find('property_type')
    if (propertyType.value === '土地' || propertyType.value === 'その他') {
      return true
    } else {
      return false
    }
  }

  /**
   * 「建物面積・専有面積」の単位が「わからない」を選択されている場合以外は、値が入力されているか検証する
   *
   * @param {any} form
   * @param {any} item
   * @return {string|true}
   */
  buildingArea (form: any, item: any): string|boolean {
    const buildingAreaUnit = form.find('building_area_unit')
    let error: string|boolean = ''
    if (this.buildingAreaRequired(form) && this.isEmptyValue(buildingAreaUnit.value)) {
      return true
    } else if (buildingAreaUnit.value === 'わからない') {
      item.display = false
      item.value = ''
    } else {
      item.display = true
      error = this.required(form, item)
    }
    return stringOrTrue(error)
  }

  /**
   * 「建物面積・専有面積」の値が入力されている場合は、単位の入力がされているか検証する
   *
   * @param {any} form
   * @param {any} item
   * @return {string|true}
   */
  buildingAreaUnit (form: any, item: any): string|boolean {
    const buildingArea = form.find('building_area')
    if (this.buildingAreaRequired(form) && this.isEmptyValue(buildingArea.value)) {
      return true
    } else {
      return this.required(form, item)
    }
  }

  /**
   * 物件の種類が「マンション一室」もしくは「一戸建て」を選択されている場合は、「間取り」が入力されているか検証する
   *
   * @param {any} form
   * @param item
   * @return {string|true}
   */
  layout (form: any, item: FormGroupItem): string|boolean {
    let error: string|boolean = ''
    const propertyType = form.find('property_type')
    if (propertyType.value === 'マンション一室' || propertyType.value === '一戸建て') {
      error = this.required(form, item)
    }
    return stringOrTrue(error)
  }

  /**
   * 物件の種類が「土地」もしくは「その他」を選択されている場合以外は、「建物を建てた年」が入力されているか検証する
   *
   * @param {any} form
   * @param item
   * @return {string|true}
   */
  builtYear (form: any, item: FormGroupItem): string|boolean {
    let error: string|boolean = ''
    const propertyType = form.find('property_type')
    if (!(propertyType.value === '土地' || propertyType.value === 'その他')) {
      error = this.required(form, item)
    }
    return stringOrTrue(error)
  }

  /**
   * 郵便番号の形式を検証する
   *
   * @param {any} form
   * @param item
   * @return {string|true}
   */
  postalCode (form: any, item: FormGroupItem): string|boolean {
    const postalCodeRegExp = /^\d{7}$/
    if (typeof item.value === 'string') {
      if (item.value.match(postalCodeRegExp) !== null) {
        return true
      }
    }
    return '郵便番号の形式が正しくありません'
  }

  /**
   * 半角数字および小数点であることを検証する
   *
   * 文字列であっても空であればエラーではないため true として返してし
   * まう。これは UI 起因の仕様だが、このメソッドだけを取り上げて読む
   * と意味が分かりにくい
   *
   * @param {any} form
   * @param item
   * @return {string|true}
   */
  numeric (form: any, item: FormGroupItem): string|boolean {
    const numericRegExp = /^[0-9]*(?:\.[0-9]+)?$/
    if (typeof item.value === 'string') {
      if (item.value.match(numericRegExp) === null) {
        return '半角数字および小数点で入力してください'
      }
    }
    return true
  }
}
