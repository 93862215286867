export default class GtagMeasurementIdInitializer {
  /**
   * @param {hostname} hostname
   */
  constructor (hostname) {
    this._hostname = hostname
  }

  /**
   * @return {boolean}
   */
  isProduction () {
    return /[a-z]+\.rakusate\.com/.test(this._hostname)
  }

  /**
   * @return {string}
   */
  measurementId () {
    if (this.isProduction()) {
      return 'UA-2703282-63'
    } else {
      return 'UA-2703282-34'
    }
  }

  /**
   * dataLayerに対してtrackingIDなど初期設定を行う
   * ※PVはGTMから取得しているため送信しないようにしている
   *
   * @param {Function} gtag
   */
  setup (gtag) {
    const id = this.measurementId()
    if (id.length > 0) {
      gtag('js', new Date())
      gtag('config', id, { send_page_view: false })
    }
  }
}
