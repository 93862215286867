export default class AnalyticsPageviewSender {
  /**
   * @param {Function} gtag
   * @param {string} measurementId
   */
  constructor (gtag, measurementId) {
    this._gtag = gtag
    this._measurementId = measurementId
  }

  /**
   * @param {string} title
   * @param {string} location
   */
  send (title, location) {
    this._gtag('event', 'page_view', {
      page_title: title,
      page_location: location
    })
  }
}
