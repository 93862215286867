<template>
  <div v-show="openGuidance" class="form__modal-overlay">
    <div class="form__modal">
      <p class="form__modal-title">入力内容に不備があります</p>
      <p class="form__modal-text">入力内容を修正してから「見積もりを依頼する」を押してください</p>
      <button class="form__modal-button" @click="$emit('closeGuidance')">閉じる</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotValidError',
  props: ['openGuidance'],
  emits: ['closeGuidance']
}
</script>
