<template>
  <component :is="errorType"
    :openGuidance="openGuidance"
    @closeGuidance="$emit('closeGuidance')" />
</template>

<script>
import ServerError from './guidance/ServerError.vue'
import NotValidError from './guidance/NotValidError.vue'

export default {
  name: 'ErrorGuidance',
  props: ['openGuidance', 'errorType'],
  emits: ['closeGuidance'],
  components: { ServerError, NotValidError }
}
</script>
