import { FormObject } from '../form/form'
import { FormGroupItem } from '../form/form_group_item'
import PostalCodeApiClient, { PseudoResponse, SuccessfulResponse } from '../lib/postal_code_api_client'

export default class FetchPrefectureAndTownUsecase {
  public readonly service: string
  private readonly form: FormObject
  private readonly client: PostalCodeApiClient

  /**
   * @param {object} params
   * @param {String} params.service
   * @param {FormObject} params.form
   */
  constructor ({
    service,
    form
  }) {
    this.service = service
    this.form = form
    this.client = new PostalCodeApiClient()
  }

  /**
   * @param item
   */
  isFetchExec (item: any): boolean {
    return typeof item !== 'undefined' && (item as FormGroupItem).key === 'zip_code'
  }

  /**
   * @param {any | undefined} item
   * @param {string} step
   */
  async execute (item: any): Promise<void> {
    if (this.isFetchExec(item)) {
      const result = await this.fetcher(item)
      if (this.isFetchSuccess(result)) {
        this.setPrefectureAndTown(result as SuccessfulResponse)
      }
    }
  }

  /**
   * @param {any} item
   * @return {Promise<PseudoResponse>}
   */
  async fetcher (item): Promise<any> {
    return await this.client.fetch(item.value)
  }

  /**
   * @param result
   * @return {boolean}
   */
  isFetchSuccess (result: PseudoResponse): boolean {
    return result.status === 200
  }

  /**
   * @param result
   */
  setPrefectureAndTown (result: SuccessfulResponse): void {
    const userPrefecture = this.form.find('user_prefecture')
    const userTown = this.form.find('user_town')
    userPrefecture.value = result.data.prefecture
    userTown.value = result.data.address1 + result.data.address2
  }
}
