// import FormObject from '../form/form.d'
import Basil from 'basil.js'

/**
 * localStorage から Store へデータを書き戻す
 *
 * 書き戻されたデータは Store を通じて component へ反映される
 */
export default class RestoreStoreUsecase {
  public readonly service: string
  private readonly form: any
  private readonly storage: Basil

  /**
   * @param {object} params
   * @param {string} params.service
   * @param {FormObject} params.form
   * @param {object} params.storage
   */
  constructor ({
    service,
    form,
    storage = Basil
  }) {
    this.service = service
    this.form = form
    this.storage = storage
  }

  execute (): any {
    return {}
  }
}
