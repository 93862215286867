import ky from 'ky-universal'
import Validator from '../form/validator'

/**
 * MadeforPostalCodeの住所データの構造のうち、県から町字レベルまでのもの
 */
interface MadeforPostalCodeCompact {
  prefecture: string
  address1: string
  address2: string
}

/**
 * MadeforPostalCodeのAPIが返してくる住所データ部分だけの構造
 */
interface MadeforPostalCodeAddresses extends MadeforPostalCodeCompact {
  address3: string
  address4: string
}

/**
 * MadeforPostalCodeのレスポンスのdataの中のワンセット
 */
interface MadeforPostalCodeSet {
  prefcode: string
  ja: MadeforPostalCodeAddresses
  en: MadeforPostalCodeAddresses
}

/**
 * MadeforPostalCodeのAPIレスポンス
 */
interface MadeforPostalCodeSchema {
  code: string
  data: MadeforPostalCodeSet[]
}

/**
 * APIクライアントで組み立てる疑似的なレスポンス
 *
 * Clientが例外を上げてしまうエラーもそのままデータとして扱えるフォーマット
 */
export interface PseudoResponse {
  status: number
  errors?: string | string[]
  data?: MadeforPostalCodeCompact
}

/**
 * APIクライアントの成功レスポンスのフォーマット
 *
 * PseudoResponse と違い、errors は存在しなくて data は必ずある
 */
export interface SuccessfulResponse {
  status: number
  data: MadeforPostalCodeCompact
}

export default class PostalCodeApiClient {
  get endpoint (): string { return 'https://madefor.github.io/postal-code-api/api/v1' }
  private readonly validator: any

  constructor () {
    this.validator = new Validator()
  }

  /**
   * @param {string} postalCode
   * @return {Promise<PseudoResponse>}
   */
  async fetch (postalCode): Promise<any> {
    if (!this.isValid(postalCode)) {
      return { status: 404, errors: 'postal code invalid!' }
    }
    const uri = this.uriBuilder(postalCode)
    try {
      const ret = await this.hitPostalCodeApi(uri)
      return { status: 200, data: this.formatter(ret) }
    } catch (error) {
      return { status: 404, errors: error.message }
    }
  }

  /**
  * @param {string} uri
  * @return {MadeforPostalCodeSchema}
  */
  async hitPostalCodeApi (uri): Promise<MadeforPostalCodeSchema> {
    return await ky(uri).json()
  }

  /**
  * @param {string} postalCode
  * @return {boolean}
  */
  isValid (postalCode?: string): boolean {
    return typeof postalCode !== 'undefined' && this.validator.postalCode('', { value: postalCode }) === true
  }

  /**
   * @param {MadeforPostalCodeSchema} ret
   * @return {MadeforPostalCodeCompact}
   */
  formatter (ret: any): any {
    return {
      prefecture: ret.data[0].ja.prefecture,
      address1: ret.data[0].ja.address1,
      address2: ret.data[0].ja.address2
    }
  }

  /**
   * @param {string} postalCode
   * @return {string}
   */
  uriBuilder (postalCode): string {
    const formatPostalCode = this.splitPostalCode(postalCode)
    return `${this.endpoint}/${formatPostalCode}.json`
  }

  /**
   * APIの仕様に合わせて整形 9218002 -> 921/8002
   * @param {string} postalCode
   * @return {string}
   */
  splitPostalCode (postalCode: string): string {
    const tmp = postalCode.replace(/-/g, '')
    return this.insertString(tmp, '/', 3)
  }

  /**
   * @param {string} val
   * @param {string} str
   * @param {number} idx
   * @return {string}
   */
  insertString (val: string, str: string, idx: number): string {
    return val.slice(0, idx) + str + val.slice(idx)
  }
}
